import { Menu, Transition } from "@headlessui/react";
import { useLocation } from "@remix-run/react";
import { Fragment, useEffect, useState } from "react";
import {
  HiOutlineBars3,
  HiOutlineChevronDown,
  HiOutlineMagnifyingGlass,
  HiOutlineShoppingCart,
  HiOutlineWrenchScrewdriver,
} from "react-icons/hi2";

import { Button } from "~/components/Button";
import { Link } from "~/components/Link";
import { Navbar } from "~/components/Navbar";
import { Searchbar } from "~/components/Searchbar";
import { LogoBadgeIcon, LogoBlackIcon } from "~/components/svg/logo";
import { Wrapper } from "~/components/ui/wrapper";
import { UserMenu } from "~/components/UserMenu";
import { headerSecondaryNavItems } from "~/config/navigation";
import { cn } from "~/utils/classnames";

import type { HeaderProps } from "./Header.types";

/**
 * Composant d'en-tête du site.
 */
export const Header = ({
  user,
  contact,
  navbarCategories = [],
  className,
  currentCart,
  ...props
}: HeaderProps) => {
  const location = useLocation();

  const [isScrolled, setIsScrolled] = useState(false);
  const [isMobileNavbarOpen, setIsMobileNavbarOpen] = useState(false);
  const [isMobileSearchbarOpen, setIsMobileSearchbarOpen] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 80) {
      setIsScrolled(true);
    }
    if (window.scrollY < 30) {
      setIsScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("wheel", handleScroll);

    return () => {
      window.removeEventListener("wheel", handleScroll);
    };
  }, []);

  const cartTotalProducts = Number(
    currentCart?.cartRows?.reduce((acc, row) => acc + Number(row.quantity), 0).toFixed(3) || 0
  );

  return (
    <header
      className={cn(
        "sticky top-0 z-40 overflow-visible border-b border-grey-100 bg-white",
        location.pathname === "/connexion" && "hidden",
        className
      )}
      onMouseEnter={() => setIsScrolled(false)}
      {...props}
    >
      {/* Header */}
      <Wrapper className="flex items-center justify-between gap-4 bg-white py-2">
        {/* Logo */}
        <Link to="/" aria-label="Revenir à la page d'accueil" className="shrink-0">
          <div className="flex items-center gap-3 md:gap-4">
            <LogoBadgeIcon className="hidden h-12 w-12 sm:block md:h-14 md:w-14 lg:h-[3.75rem] lg:w-[3.75rem]" />
            <LogoBlackIcon className="h-8 sm:h-10 md:h-11 lg:h-12" />
          </div>
          <Transition
            as="div"
            show={!isScrolled}
            className="hidden transition-all duration-500 lg:block"
            enterFrom="opacity-0 max-h-0 scale-x-75 scale-y-0 max-w-[200px]"
            enterTo="max-h-5 opacity-100 scale-100 max-w-[240px]"
            leaveFrom="max-h-5 opacity-100 scale-100 max-w-[240px]"
            leaveTo="opacity-0 max-h-0 scale-x-75 scale-y-0 max-w-[200px]"
          >
            <div className="pt-2 text-[11px] font-medium uppercase">
              Renewable Power Technologies
            </div>
          </Transition>
        </Link>

        {/* Content */}
        <div className="grow">
          {/* Secondary Links */}
          <Transition
            show={!isScrolled}
            className="relative z-40 hidden transition-all duration-500 lg:block"
            data-testid="top-bar-menu"
            enterFrom="opacity-0 max-h-0 scale-y-0"
            enterTo="max-h-9 opacity-100 scale-y-100"
            leaveFrom="max-h-9 opacity-100 scale-y-100"
            leaveTo="opacity-0 max-h-0 scale-y-0"
          >
            <nav
              className="flex justify-end gap-4 pb-2 xl:gap-8"
              aria-label="Navigation secondaire"
            >
              {headerSecondaryNavItems.map((item) =>
                item.children && item.children.length > 0 ? (
                  <Menu key={`${item.label}-${item.to}`} as="div" className="relative">
                    <Menu.Button as={Fragment}>
                      <Button
                        size="xs"
                        variant="ghost"
                        color="black"
                        uppercase
                        className="text-[11px] font-medium"
                        data-testid="ressource-nav-button"
                      >
                        {item.label}
                        <HiOutlineChevronDown className="h-3 w-3" />
                      </Button>
                    </Menu.Button>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute left-0 mt-2 w-32 origin-top-left divide-y divide-grey-100 rounded-lg border border-grey-100 bg-white shadow-md">
                        <div className="space-y-1 p-2">
                          {item.children.map((child) => (
                            <Menu.Item key={`${child.label}-${child.to}`}>
                              {({ active }) => (
                                <Link
                                  to={child.to}
                                  className={cn(
                                    "block w-full px-4 py-2 text-start text-xs font-medium text-grey-900 hover:bg-grey-50",
                                    active && "bg-grey-50"
                                  )}
                                >
                                  {child.label}
                                </Link>
                              )}
                            </Menu.Item>
                          ))}
                        </div>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                ) : (
                  <Button
                    key={`${item.label}-${item.to}`}
                    as="link"
                    to={item.to || "/"}
                    size="xs"
                    variant="ghost"
                    color="black"
                    uppercase
                    className="text-[11px] font-medium"
                  >
                    {item.icon ? <item.icon className="h-4 w-4 text-primary" /> : null}
                    {item.label}
                  </Button>
                )
              )}
            </nav>
          </Transition>

          {/* Main links and buttons */}
          <nav
            className="flex items-center justify-end gap-2 sm:gap-4"
            aria-label="Navigation compte et administration"
            data-testid="mobile-nav"
          >
            <Searchbar
              className={cn(
                "!hidden w-full max-w-none bg-white max-md:!absolute max-md:left-0 max-md:top-full max-md:border-y max-md:border-grey-100 max-md:p-4 md:mx-auto md:!flex md:max-w-sm",
                isMobileSearchbarOpen && "!flex"
              )}
              isMobileSearchbarOpen={isMobileSearchbarOpen}
              setIsMobileSearchbarOpen={setIsMobileSearchbarOpen}
              isUserConnected={!!user}
            />
            {user?.role === "ROLE_ADMIN" ? (
              <Button
                as="link"
                to="/admin/pages"
                size="lg"
                variant="ghost"
                color="black"
                className="shrink-0 max-lg:h-auto max-lg:px-2 max-lg:py-2"
              >
                <HiOutlineWrenchScrewdriver className="h-6 w-6 shrink-0 text-primary" />
                <span className="sr-only lg:not-sr-only">Administration</span>
              </Button>
            ) : null}
            <Button
              onClick={() => setIsMobileSearchbarOpen(true)}
              size="lg"
              variant="ghost"
              color="black"
              aria-label="Ouvrir la barre de recherche"
              className="shrink-0 max-lg:h-auto max-lg:px-2 max-lg:py-2 md:hidden"
            >
              <HiOutlineMagnifyingGlass className="h-6 w-6 shrink-0 text-primary" />
            </Button>
            <UserMenu user={user} contact={contact} />
            <Button
              as="link"
              to="/cart"
              size="lg"
              className="relative shrink-0 max-lg:h-auto max-lg:bg-transparent max-lg:px-2 max-lg:py-2 max-lg:text-primary max-lg:hover:bg-grey-50"
            >
              <HiOutlineShoppingCart className="h-6 w-6 shrink-0" />
              <div className="sr-only flex flex-col gap-1 lg:not-sr-only">
                <span className="sr-only lg:not-sr-only">Mon Panier</span>
                <span className="sr-only text-xs lg:not-sr-only">
                  {currentCart?.label.length && currentCart.label.length > 16
                    ? `${currentCart.label.slice(0, 16)}...`
                    : currentCart?.label}
                </span>
              </div>
              {cartTotalProducts && cartTotalProducts > 0 ? (
                <span className="absolute right-0 top-0 hidden h-5 min-h-[1.25rem] min-w-[1.25rem] -translate-y-0.5 translate-x-0.5 items-center justify-center rounded-full border border-primary bg-primary-50 px-1.5 text-[11px] text-primary-600 lg:flex">
                  {cartTotalProducts}
                </span>
              ) : null}
            </Button>
            <Button
              size="lg"
              variant="ghost"
              color="black"
              className="h-auto shrink-0 px-2 py-2 lg:hidden"
              onClick={() => setIsMobileNavbarOpen((prev) => !prev)}
              data-testid="burger-menu"
              aria-label={
                isMobileNavbarOpen ? "Fermer le menu de navigation" : "Ouvrir le menu de navigation"
              }
            >
              <HiOutlineBars3 className="h-8 w-8 shrink-0 text-primary" />
            </Button>
          </nav>
        </div>
      </Wrapper>

      {/* Navbar */}
      <Navbar
        categories={navbarCategories || []}
        isMobileOpen={isMobileNavbarOpen}
        setIsMobileOpen={setIsMobileNavbarOpen}
      />
    </header>
  );
};
