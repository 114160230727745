import type { User } from "~types/api/user.types";

import { ButtonOld } from "~/components/ButtonOld";
import { Modal } from "~/components/Modal";
import { Typo } from "~/components/Typo";

export const PasswordExpiresSoonModal = ({
  user,
  open,
  onClose,
}: {
  user: User | null;
  open: boolean;
  onClose: () => void;
}) => {
  const daysBeforeExpiration = user?.contacts[0]?.datePasswordExpires
    ? Math.floor(
        (new Date(user.contacts[0]?.datePasswordExpires).getTime() - new Date().getTime()) /
          (1000 * 3600 * 24)
      )
    : null;

  const daysBeforeExpirationText = daysBeforeExpiration
    ? `${daysBeforeExpiration} jours`
    : "moins de 10 jours";

  return (
    <Modal title="Votre mot de passe va bientôt expirer." isOpen={open} size="lg" onClose={onClose}>
      <div className="flex flex-col items-center gap-4 text-center">
        <Typo className="leading-normal">
          Par mesure de sécurité, nous demandons à nos utilisateurs de changer leur mot de passe
          tous les 3 mois.
        </Typo>
        <Typo className="mb-4 leading-normal">
          Le vôtre expire dans {daysBeforeExpirationText}, veuillez le modifier.
        </Typo>
        <ButtonOld
          as="a"
          href="/account/profile"
          color="primary"
          label="Changer mon mot de passe"
          className="mx-auto"
          onClick={onClose}
        />
      </div>
    </Modal>
  );
};
