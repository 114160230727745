import { useFetcher } from "@remix-run/react";
import { AuthenticityTokenInput } from "remix-utils/csrf/react";
import { ValidatedForm } from "remix-validated-form";
import type { User } from "~types/api/user.types";

import { Button } from "~/components/Button";
import { ButtonOld } from "~/components/ButtonOld";
import { ValidatedFormInput } from "~/components/Input/Input";
import { LoadingSpinner } from "~/components/LoadingSpinner";
import { Modal } from "~/components/Modal";
import { Typo } from "~/components/Typo";
import { useManagedFetcher } from "~/hooks/use-managed-fetcher";
import { changePasswordValidator } from "~/validators/password";

export const ExpiredPasswordModal = ({
  user,
  open,
  onClose,
}: {
  user: User | null;
  open: boolean;
  onClose: () => void;
}) => {
  const fetcher = useFetcher<any>();
  const [isFetcherLoading, isFetcherDone] = useManagedFetcher({
    fetcher,
    errorAsToast: true,
    toastSuccessMessage: "Votre mot de passe a été modifié avec succès",
  });

  return (
    <Modal
      title={isFetcherDone ? "Mot de passe modifié" : "Votre mot de passe a expiré"}
      isOpen={open}
      forceOpen={!isFetcherDone}
      size="lg"
      onClose={isFetcherDone ? onClose : () => null}
    >
      <div className="flex flex-col items-center gap-4 text-center">
        {isFetcherDone ? (
          <>
            <Typo className="leading-normal">Votre mot de passe a été modifié avec succès.</Typo>
            <ButtonOld
              color="black"
              label="Accéder au site"
              type="button"
              onClick={onClose}
              className="mx-auto"
            />
          </>
        ) : (
          <>
            <Typo className="leading-normal">
              Votre mot de passe n&apos;a pas été modifié depuis plus de 3 mois.
            </Typo>

            <Typo className="mb-4 leading-normal">
              Par mesure de sécurité, il doit être renouvelé :
            </Typo>

            <ValidatedForm
              fetcher={fetcher}
              action="/account/profile/informations"
              method="post"
              validator={changePasswordValidator}
              className="w-full max-w-sm"
            >
              <input type="hidden" name="_action" value="changePassword" />
              <AuthenticityTokenInput />

              <div className="flex w-full flex-col items-stretch gap-4">
                <ValidatedFormInput
                  id="oldPassword"
                  name="oldPassword"
                  label="Mot de passe actuel"
                  type="password"
                />
                <ValidatedFormInput
                  id="newPassword"
                  name="newPassword"
                  type="password"
                  label="Nouveau mot de passe"
                />
                <ValidatedFormInput
                  id="confirmNewPassword"
                  name="confirmNewPassword"
                  type="password"
                  label="Confirmer le nouveau mot de passe"
                />
                <ValidatedFormInput
                  type="hidden"
                  id="userId"
                  name="userId"
                  value={user?.id.toString() || ""}
                />
              </div>
              <ButtonOld
                color="black"
                label="Modifier le mot de passe"
                type="submit"
                className="mx-auto mt-4"
                IconComponent={isFetcherLoading ? <LoadingSpinner /> : null}
              />
            </ValidatedForm>

            <Button
              as="button"
              size="sm"
              variant="link"
              color="black"
              className="mt-4"
              onClick={() => {
                fetcher.submit(
                  {},
                  {
                    method: "POST",
                    action: "/logout?redirect=/connexion?forgotPassword=1",
                  }
                );
              }}
            >
              J&apos;ai oublié mon mot de passe
            </Button>
          </>
        )}
      </div>
    </Modal>
  );
};
