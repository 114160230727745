import { useFetcher, useLocation, useNavigate } from "@remix-run/react";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import type { Product } from "~types/api/product.types";

import alternativeImage from "~/assets/images/fallback-images/main-image-fallback.png";
import { Input } from "~/components/Input";
import { Link } from "~/components/Link";
import { CartAddIcon, CheckIcon, SearchIcon } from "~/components/svg/icons";
import { useDebounce } from "~/hooks/use-debounce";
import { useManagedFetcher } from "~/hooks/use-managed-fetcher";
import { useOutsideClick } from "~/hooks/use-outside-click";
import { getCharacteristicValue } from "~/services/characteristics";
import { cn } from "~/utils/classnames";

import { ButtonOld } from "../ButtonOld";
import { LoadingSpinner } from "../LoadingSpinner";
import { Modal } from "../Modal";
import "./Searchbar.css";
import type { SearchbarProps } from "./Searchbar.types";

const SearchbarItem = ({
  product,
  subheading,
  onClick,
  isUserConnected,
}: {
  product: Product;
  subheading: string;
  onClick: () => void;
  isUserConnected: boolean;
}) => {
  const [isReassortModalOpen, setIsReassortModalOpen] = useState(false);
  const [isReassortModalAlreadyOpened, setIsReassortModalAlreadyOpened] = useState(false);

  const cartFetcher = useFetcher<any>();

  const [isCartFetcherLoading, isCartFetcherDone] = useManagedFetcher({
    fetcher: cartFetcher,
    toastSuccessMessage: "Produit ajouté au panier",
  });

  useEffect(() => {
    if (
      (product.stocks[0]?.etatStock === 3 || product.stocks[0]?.etatStock === 4) &&
      isCartFetcherDone &&
      !isReassortModalAlreadyOpened
    ) {
      setIsReassortModalOpen(true);
      setIsReassortModalAlreadyOpened(true);
    }
  }, [isCartFetcherDone, product, isReassortModalAlreadyOpened]);

  return (
    <div className="Searchbar-dropdown-item">
      <Modal
        title="Produit ajouté au panier"
        description={
          product.stocks[0]?.etatStock === 3
            ? "Ce produit est en cours de réapprovisionnement, le prix est donc susceptible d'évoluer"
            : product.stocks[0]?.etatStock === 4
              ? "Ce produit est sur commande, le prix est donc susceptible d'évoluer"
              : null
        }
        size="sm"
        isOpen={isReassortModalOpen}
        onClose={() => setIsReassortModalOpen(false)}
        className="ProductCard-modal"
      >
        <ButtonOld
          label="OK"
          color="black"
          onClick={() => {
            setIsReassortModalOpen(false);
          }}
          className="mx-auto"
        />
      </Modal>
      <img
        src={product.images[0]?.url || alternativeImage}
        alt={product.title}
        className="Searchbar-dropdown-item-image"
      />
      <Link className="Searchbar-dropdown-item-content" to={product.slug || ""} onClick={onClick}>
        <p className="Searchbar-dropdown-item-title">{product.title}</p>
        <p className="Searchbar-dropdown-item-subHeading">{subheading}</p>
      </Link>

      {isUserConnected ? (
        <cartFetcher.Form
          className="Searchbar-dropdown-item-form"
          method="post"
          action="/cart/actions?/addItem"
        >
          <input type="hidden" name="productId" value={product.id} />
          <input type="hidden" name="quantity" value={1} />
          <ButtonOld
            type="submit"
            IconComponent={
              isCartFetcherDone ? (
                <CheckIcon />
              ) : isCartFetcherLoading ? (
                <LoadingSpinner />
              ) : (
                <CartAddIcon />
              )
            }
            color="primary"
            className="Searchbar-dropdown-item-addToCart"
            iconClassName="Searchbar-dropdown-item-addToCart-icon svgIcon"
          />
        </cartFetcher.Form>
      ) : null}
    </div>
  );
};

export const Searchbar = ({
  isMobileSearchbarOpen,
  setIsMobileSearchbarOpen,
  isUserConnected,
  className,
}: SearchbarProps) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isPending, setIsPending] = useState(false);
  const [searchSuggestions, setSearchSuggestions] = useState<Product[] | null>(null);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [searchValue, setSearchValue] = useState<string>("");
  const debouncedSearchValue = useDebounce<string>(searchValue, 300);

  const handleSearch = async (event: any) => {
    const { value } = event.target;
    setSearchValue(value);
  };

  const searchbarRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const location = useLocation();
  const navigate = useNavigate();

  // Listen for a route change and close the dropdown
  useEffect(() => {
    setIsDropdownOpen(false);
    if (inputRef.current) {
      inputRef.current.value = "";
    }
  }, [location.pathname]);

  const handleClick = () => {
    setIsDropdownOpen((isDropdownOpen) => !isDropdownOpen);
  };

  useOutsideClick(searchbarRef, () => {
    setIsDropdownOpen(false);
    setIsMobileSearchbarOpen(false);
  });

  useEffect(() => {
    setIsPending(true);
    setIsDropdownOpen(true);
    const search = async () => {
      const searchResponse = await axios.get<{ data: Product[]; totalItems: number }>(
        `/product/${debouncedSearchValue}`
      );
      setIsPending(false);
      setSearchSuggestions(searchResponse.data.data || []);
      setTotalItems(searchResponse.data.totalItems || 0);
    };

    if (debouncedSearchValue.length === 0) {
      setSearchSuggestions(null);
      setIsDropdownOpen(false);
    } else {
      search();
    }
  }, [debouncedSearchValue]);

  return (
    <div
      className={cn("Searchbar", isMobileSearchbarOpen && "Searchbar--open", className)}
      ref={searchbarRef}
    >
      <Input
        name="header-searchbar"
        id="header-searchbar"
        className="Searchbar-input"
        IconComponent={<SearchIcon />}
        onIconClick={() => {
          setIsDropdownOpen(false);
          setIsMobileSearchbarOpen(false);
          navigate(`/recherche?search=${searchValue}`);
        }}
        placeholder="Rechercher un produit"
        onKeyUp={handleSearch}
        ref={inputRef}
      />
      {isDropdownOpen ? (
        <div className="Searchbar-dropdown">
          <div className="Searchbar-dropdown-container">
            {Array.isArray(searchSuggestions) && searchSuggestions.length > 0 && !isPending
              ? searchSuggestions.map((product) => {
                  const subheading = getCharacteristicValue(product, "sous-titre-vignette");
                  return (
                    <SearchbarItem
                      key={product.id}
                      product={product}
                      subheading={subheading || ""}
                      onClick={handleClick}
                      isUserConnected={isUserConnected}
                    />
                  );
                })
              : null}
            {isPending ? <p className="Searchbar-dropdown-noProductItem">Chargement</p> : null}
            {!isPending && searchSuggestions?.length === 0 ? (
              <p className="Searchbar-dropdown-noProductItem">Aucun produit trouvé</p>
            ) : null}
          </div>
          {searchSuggestions && searchSuggestions.length > 5 && !isPending ? (
            <Link
              to={`/recherche?search=${searchValue}`}
              className="Searchbar-dropdown-seeAllResults"
            >
              Voir les {totalItems} résultats
            </Link>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};
