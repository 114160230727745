import type {
  LinksFunction,
  LoaderFunctionArgs,
  MetaFunction,
  SerializeFrom,
} from "@remix-run/node";
import { json, redirect } from "@remix-run/node";
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useMatches,
} from "@remix-run/react";
import { withSentry } from "@sentry/remix";
import { Suspense, useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import toastifyStyles from "react-toastify/dist/ReactToastify.css?url";
import { AuthenticityTokenProvider } from "remix-utils/csrf/react";
import { ExternalScripts } from "remix-utils/external-scripts";
import swiperStyles from "swiper/swiper-bundle.css?url";

import { Analytics } from "~/components/Analytics";
import { ChangeContactModal } from "~/components/ChangeContactModal";
import { ComparatorButton } from "~/components/ComparatorButton";
import { ErrorComponent } from "~/components/ErrorComponent";
import { ExpiredPasswordModal } from "~/components/ExpiredPasswordModal/ExpiredPasswordModal";
import { Footer } from "~/components/Footer";
import { GlobalLoading } from "~/components/GlobalLoading";
import { Header } from "~/components/Header";
import { LoadingSpinner } from "~/components/LoadingSpinner";
import { PasswordExpiresSoonModal } from "~/components/PasswordExpiresSoonModal/PasswordExpiresSoonModal";
import { TooltipProvider } from "~/components/Tooltip";
import { SEO_META_DEFAULT_CONFIG } from "~/config/seo";
import { useCartAnalytics } from "~/hooks/use-cart-analytics";
import { useContactRestrictionToast } from "~/hooks/use-contact-restriction-toast";
import { useExpiredPassword } from "~/hooks/use-expired-password";
import { useGlobalErrorToast } from "~/hooks/use-global-error-toast";
import { getActiveCart } from "~/models/cart.server";
import { getCategories } from "~/models/category.server";
import { getContact } from "~/models/contact.server";
import { getFavorites } from "~/models/favorite.server";
import { getSession, logout, sessionStorage } from "~/models/session.server";
import { getSettings } from "~/models/setting.server";
import { getUser } from "~/models/user.server";
import globalStyles from "~/styles/global.css?url";
import { api } from "~/utils/api-fetcher.server";
import { commitCsrf } from "~/utils/csrf.server";
import { handleError } from "~/utils/errors.server";
import { useNonce } from "~/utils/nonce";
import { getSeoMeta } from "~/utils/seo";
import { parseSessionData } from "~/utils/sessions";

export const meta: MetaFunction = (metaArgs) => {
  return getSeoMeta(metaArgs, {
    title: SEO_META_DEFAULT_CONFIG.title,
    description: SEO_META_DEFAULT_CONFIG.description,
  });
};

const favicons = [
  { rel: "icon", href: "/favicon.ico", type: "image/x-icon" },
  { rel: "icon", href: "/icons/icon-16.png", type: "image/png", sizes: "16x16" },
  { rel: "icon", href: "/icons/icon-32.png", type: "image/png", sizes: "32x32" },
  { rel: "apple-touch-icon", href: "/icons/apple-touch-icon-180.png", sizes: "180x180" },
  { rel: "manifest", href: "/site.webmanifest", crossOrigin: "use-credentials" },
];

const stylesheets = [
  { rel: "stylesheet", href: toastifyStyles },
  { rel: "stylesheet", href: swiperStyles }, // TODO: import only in components that use swiper
  { rel: "stylesheet", href: globalStyles },
];

export const links: LinksFunction = () => {
  return [...favicons, ...stylesheets];
};

export type RootLoader = typeof loader;

export const loader = async ({ request }: LoaderFunctionArgs) => {
  try {
    // Redirect if url contains /fr/ (old url format)
    if (request.url.includes("/fr/")) {
      const newUrl = new URL(request.url);
      newUrl.pathname = newUrl.pathname.replace("/fr/", "/");
      throw redirect(newUrl.toString(), 301);
    }

    // Redirect if url contains outdated search params (old url format)
    // redirectIfOutdatedUrlSearchParams({ request });

    // Get CSRF token for app
    const { csrfToken, csrfCookieHeader } = await commitCsrf();

    const ENV = {
      VITE_APP_URL: process.env.VITE_APP_URL ?? "",
      PORT: process.env.PORT ?? "",
      API_URL: process.env.API_URL ?? "",
      API_ROOT_URL: process.env.API_ROOT_URL ?? "",
      RECAPTCHA_SITE_KEY: process.env.RECAPTCHA_SITE_KEY ?? "",
      GOOGLE_SITE_VERIFICATION: process.env.GOOGLE_SITE_VERIFICATION ?? "",
      GA_TRACKING_ID: process.env.GA_TRACKING_ID ?? "",
      HOTJAR_ID: process.env.HOTJAR_ID ?? "",
      AXEPTIO_CLIENT_ID: process.env.AXEPTIO_CLIENT_ID ?? "",
      SENTRY_DSN: process.env.SENTRY_DSN ?? "",
      SENTRY_HOSTING_ENV: process.env.SENTRY_HOSTING_ENV ?? "",
    };

    const session = await getSession({ request });

    // Remove extra keys from session and reset session if types are not correct
    parseSessionData(session);

    const user = await getUser({ request });
    let activeContactId = session.get("activeContactId") ?? null;

    // Logout if active contact is not in user contacts
    if (
      activeContactId &&
      user?.contacts.findIndex((contact) => contact.id === activeContactId) === -1
    ) {
      const path = new URL(request.url).pathname;
      throw logout({ redirectTo: path, request });
    }

    // Set active contact if user has only one contact
    if (!activeContactId && user?.contacts.length === 1) {
      activeContactId = user.contacts[0]?.id ?? null;
      session.set("activeContactId", activeContactId);
      session.set("activeCompanyId", user.contacts[0]?.company?.id ?? null);
    }

    // Daily log for active contact
    const lastContactLog = session.get("lastContactLog");
    const isLastContactLogToday =
      !!lastContactLog && new Date(lastContactLog).toDateString() === new Date().toDateString();
    if (activeContactId && !isLastContactLogToday) {
      await api({ request }).post(`/account/login/${activeContactId}`, {});
      session.set("lastContactLog", new Date().toISOString());
    }

    // Get data
    const [settings, categories, contact, currentCart, favorites] = await Promise.all([
      getSettings({ request }),
      getCategories({ request }),
      user && activeContactId ? getContact({ request, id: activeContactId }) : null,
      user && activeContactId ? getActiveCart({ request }) : null,
      user && activeContactId ? getFavorites({ contactId: activeContactId, request }) : null,
    ]);

    const headers = new Headers();
    headers.append("Set-Cookie", csrfCookieHeader || "");
    headers.append("Set-Cookie", await sessionStorage.commitSession(session));

    return json(
      {
        settings,
        activeContactId,
        user,
        contact,
        currentCart,
        comparator: session.get("comparator") || null,
        favorites,
        categories,
        csrfToken,
        isUserConnected: user?.id ? true : false,
        isPasswordExpired: user?.contacts[0]?.passwordExpired || false,
        companyContacts: user?.contacts || null,
        ENV,
      },
      { headers }
    );
  } catch (error) {
    return handleError(error, {
      settings: null,
      activeContactId: null,
      user: null,
      contact: null,
      currentCart: null,
      comparator: null,
      favorites: null,
      categories: null,
      csrfToken: null,
      isUserConnected: false,
      isPasswordExpired: false,
      companyContacts: null,
      ENV: null,
    });
  }
};

function Document({
  children,
  nonce,
  noIndex = false,
  env = {},
}: {
  children: React.ReactNode;
  nonce: string;
  noIndex?: boolean;
  env?: Record<string, string> | null;
}) {
  return (
    <html lang="fr" className={`h-full overflow-x-hidden`}>
      <head>
        {noIndex ? <meta name="robots" content="noindex" /> : null}
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <Meta />
        <Links />

        <Analytics
          axeptioClientId={env?.AXEPTIO_CLIENT_ID}
          gaTrackingId={env?.GA_TRACKING_ID}
          hotjarId={env?.HOTJAR_ID}
          googleSiteVerification={env?.GOOGLE_SITE_VERIFICATION}
          nonce={nonce}
        />
      </head>
      <body className="text-grey-900">
        {/* App */}
        {children}

        {/* Scripts */}
        <script
          nonce={nonce}
          dangerouslySetInnerHTML={{
            __html: `window.ENV = ${JSON.stringify(env)}`,
          }}
        />
        <ScrollRestoration nonce={nonce} />
        <Scripts nonce={nonce} />
        <ExternalScripts />
      </body>
    </html>
  );
}

function App() {
  const data = useLoaderData<typeof loader>();
  const nonce = useNonce();
  const matches = useMatches();

  const hideComparator = matches.some(
    (match) => match.id === "routes/_protected.cart" || match.id === "routes/admin"
  );

  const {
    isExpiredPasswordModalOpen,
    isPasswordExpiresSoonModalOpen,
    setIsExpiredPasswordModalOpen,
    setIsPasswordExpiresSoonModalOpen,
    setIsPasswordExpiresSoonModalSeen,
  } = useExpiredPassword({ user: data.user, contact: data.contact });

  const [isContactModalOpen, setIsContactModalOpen] = useState(false);

  useEffect(() => {
    if (data.user && !data.contact && !isExpiredPasswordModalOpen) {
      setIsContactModalOpen(true);
    }
  }, [data.user, data.contact, isExpiredPasswordModalOpen]);

  useContactRestrictionToast({ contact: data.contact });
  useCartAnalytics({ currentCart: data.currentCart });
  useGlobalErrorToast();

  return (
    <Document nonce={nonce} env={data.ENV}>
      {/* Header, Main content and Footer */}
      <Header
        user={data.user}
        contact={data.contact}
        navbarCategories={data.categories || []}
        currentCart={data.currentCart || null}
      />
      <main>
        <Suspense fallback={<LoadingSpinner className="mx-auto my-32" />}>
          <Outlet />
        </Suspense>
      </main>
      <Footer navbarCategories={data.categories || []} />

      {/* Modals */}
      {isContactModalOpen ? (
        <ChangeContactModal
          activeContact={data.contact}
          contacts={data.user?.contacts || []}
          onClose={() => (data.contact ? setIsContactModalOpen(false) : null)}
          open={isContactModalOpen}
        />
      ) : null}
      {isExpiredPasswordModalOpen ? (
        <ExpiredPasswordModal
          user={data.user}
          onClose={() =>
            data.user?.contacts[0]?.passwordExpired ? null : setIsExpiredPasswordModalOpen(false)
          }
          open={isExpiredPasswordModalOpen}
        />
      ) : null}
      {isPasswordExpiresSoonModalOpen ? (
        <PasswordExpiresSoonModal
          user={data.user}
          onClose={() => {
            setIsPasswordExpiresSoonModalOpen(false);
            setIsPasswordExpiresSoonModalSeen(true);
            window.sessionStorage.setItem("passwordExpiresSoonModalSeen", "true");
          }}
          open={isPasswordExpiresSoonModalOpen}
        />
      ) : null}

      {/* Comparator button */}
      {!hideComparator ? (
        <ComparatorButton
          totalItems={data.comparator?.products?.length || data.comparator?.ranges?.length || null}
        />
      ) : null}

      <ToastContainer autoClose={3000} limit={2} position="bottom-center" />
      <GlobalLoading />
    </Document>
  );
}

function AppWithProviders() {
  const data = useLoaderData<typeof loader>();
  return (
    <AuthenticityTokenProvider token={data.csrfToken || ""}>
      <TooltipProvider delayDuration={300} skipDelayDuration={500}>
        <App />
      </TooltipProvider>
    </AuthenticityTokenProvider>
  );
}

export default withSentry(AppWithProviders);

export function ErrorBoundary() {
  // the nonce doesn't rely on the loader so we can access that
  const nonce = useNonce();

  // NOTE: you cannot use useLoaderData in an ErrorBoundary because the loader
  // likely failed to run so we have to do the best we can.
  // We could probably do better than this (it's possible the loader did run).
  // This would require a change in Remix.

  // Just make sure your root route never errors out and you'll always be able
  // to give the user a better UX.

  return (
    <Document nonce={nonce} noIndex>
      <ErrorComponent />
    </Document>
  );
}

export function useRootMatchData() {
  const matches = useMatches();
  const match = matches.find((m) => m.id === "root");
  const [data, setData] = useState(match?.data || null);

  useEffect(() => {
    if (match && match.data) {
      setData(match.data);
    }
  }, [match]);

  return data as SerializeFrom<typeof loader> | null;
}
