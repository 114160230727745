import { useEffect, useState } from "react";
import type { Cart } from "~types/api/cart.types";

import { gtag } from "~/utils/gtags.client";

export function useCartAnalytics({ currentCart }: { currentCart: Cart | null }) {
  // State for cart tracking
  const [cart, setCart] = useState(currentCart || null);
  const [cartAmount, setCartAmount] = useState(parseFloat(currentCart?.totalTtc || "0"));
  const [cartQuantity, setCartQuantity] = useState(
    (currentCart?.cartRows || []).reduce((a, b) => a + Number(b.quantity), 0)
  );

  useEffect(() => {
    if (!currentCart) return;

    const newCart = currentCart;
    const newCartAmount = parseFloat(currentCart.totalTtc || "0");
    const newCartQuantity = currentCart.cartRows.reduce((a, b) => a + Number(b.quantity), 0);

    if (newCartQuantity > cartQuantity) {
      gtag.addToCartEvent({
        newCart: currentCart,
        addedAmountWithTax: newCartAmount - cartAmount,
      });
    }

    if (newCartQuantity < cartQuantity) {
      gtag.removeFromCartEvent({
        newCart: currentCart,
        removedAmountWithTax: newCartAmount - cartAmount,
      });
    }

    setCart(newCart);
    setCartAmount(newCartAmount);
    setCartQuantity(newCartQuantity);
  }, [currentCart, cartQuantity, cartAmount, cart]);
}
