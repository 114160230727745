import * as React from "react";
import type { SVGProps } from "react";
const SvgLogoWhite = (props: SVGProps<SVGSVGElement>) => <svg {...props} xmlns="http://www.w3.org/2000/svg" style={{
  shapeRendering: "geometricPrecision",
  textRendering: "geometricPrecision",
  imageRendering: "auto",
  fillRule: "evenodd",
  clipRule: "evenodd"
}} viewBox="41.5 441.32 1603 576.84"><path fill="#FFF" d="M586.5 441.5q82.97-3.09 148 47.5 81.669 73.495 62.5 182.5Q771.593 776.413 669.5 812q-81.873 24.928-162-5Q405.27 762.26 391 651.5 383.614 521.472 498.5 462q42.2-18.803 88-20.5m-7 57q77.905-6.962 131.5 49 45.783 58.689 23 130-26.733 66.728-96.5 84.5-60.636 13.874-117-12-84.009-48.787-68.5-145.5 19.72-78.222 97.5-100.5a306 306 0 0 1 30-5.5" style={{
    opacity: 0.984
  }} /><path fill="#FFF" d="M41.5 447.5q101.5-.25 203 .5 70.827 11.339 95.5 78.5 20.863 86.463-52.5 135.5-23.655 13.885-51 17-68.498.75-137 .5v138h-58zm58 56q68.559-.713 137 1.5 56.103 17.972 44.5 75.5-14.66 35.668-52.5 42.5-64.498.75-129 .5z" style={{
    opacity: 0.986
  }} /><path fill="#fab900" d="M1315.5 447.5c68.67-.167 137.33 0 206 .5q69.225 10.753 97.5 74.5 25.56 89.836-52.5 140.5a281 281 0 0 0-20 10.5c32.79 48.08 65.46 96.247 98 144.5a865 865 0 0 1-72-.5 18954 18954 0 0 0-92-136.5c-35.67-.5-71.33-.667-107-.5v137h-58zm58 56q66-.25 132 .5c19.48 2.823 35.65 11.656 48.5 26.5 11.23 16.409 13.57 34.076 7 53-9.83 22.829-27 36.329-51.5 40.5-45.33.5-90.67.667-136 .5z" style={{
    opacity: 0.989
  }} /><path fill="#fab900" d="M826.5 539.5q29.517-.5 59 1a66168 66168 0 0 0 62.5 194 15441 15441 0 0 0 71.5-150q21-2 42 0l73.5 153q36-98.769 70.5-198h56a27669 27669 0 0 1-102.5 276c-6.6 1.313-13.43 1.98-20.5 2l-20-1a18712 18712 0 0 0-78.5-166 18745 18745 0 0 1-76.5 166q-19.5 2-39 0a34272 34272 0 0 1-98-277" style={{
    opacity: 0.991
  }} /><path fill="#FFF" d="M518.5 874.5q45.069-4.741 74.5 29 21.119 30.97 10 67-14.76 36.754-53.5 45.5-39.462 8.265-72-15-37.229-35.17-19.5-83.5 18.787-37.525 60.5-43m1 22q33.55-4.406 55.5 21 21.17 36.464-8.5 66.5-37 25.005-74 0-25.255-24.278-13.5-57.5 12.92-25.068 40.5-30" style={{
    opacity: 0.958
  }} /><path fill="#FFF" d="M367.5 875.5q31.898-3.399 59 13.5a79.3 79.3 0 0 1 11 10 79 79 0 0 1-14 17.5q-29.04-27.84-67-14.5-35.722 18.702-27.5 58.5 9.111 26.607 36.5 33.5 33.465 7.01 59-15.5a127.4 127.4 0 0 1 12 18c-27.016 21.83-57.016 27-90 15.5q-50.347-25.446-40.5-81.5 9.599-37.099 45.5-50.5a6416 6416 0 0 0 16-4.5m801 0c21.26-2.266 40.93 2.234 59 13.5a79 79 0 0 1 11 10 79 79 0 0 1-14 17.5c-19.36-18.56-41.69-23.393-67-14.5-23.81 12.468-32.98 31.968-27.5 58.5 6.07 17.738 18.24 28.905 36.5 33.5 22.31 4.673 41.98-.494 59-15.5a128 128 0 0 1 12 18c-27.02 21.83-57.02 27-90 15.5q-50.355-25.446-40.5-81.5c6.4-24.732 21.57-41.566 45.5-50.5 5.46-1.527 10.79-3.027 16-4.5" style={{
    opacity: 0.955
  }} /><path fill="#FFF" d="M632.5 876.5q11.011-.25 22 .5L749 983.5a2397 2397 0 0 1-1.5-107h22v139c-7.696.33-15.362 0-23-1l-94.5-105a988 988 0 0 1 3.5 106h-23zm174 0q11.011-.25 22 .5L923 983.5a2397 2397 0 0 1-1.5-107h22v139c-7.696.33-15.362 0-23-1l-94.5-105a988 988 0 0 1 3.5 106h-23z" style={{
    opacity: 0.961
  }} /><path fill="#FFF" d="M979.5 876.5h104v22h-81v35h70v22h-70v39h81v21h-104z" style={{
    opacity: 0.958
  }} /><path fill="#FFF" d="M1249.5 876.5h116v22h-47v117h-23v-117h-46z" style={{
    opacity: 0.948
  }} /></svg>;
export default SvgLogoWhite;