import { Disclosure, Menu, Transition } from "@headlessui/react";
import { Form, useLocation, useNavigate } from "@remix-run/react";
import type { ComponentPropsWithoutRef } from "react";
import { Fragment, useState } from "react";
import {
  HiOutlineArrowRightOnRectangle,
  HiOutlineChevronDown,
  HiOutlineUser,
} from "react-icons/hi2";
import type { Contact } from "~types/api/contact.types";
import type { User } from "~types/api/user.types";

import { Button } from "~/components/Button";
import { ChangeContactModal } from "~/components/ChangeContactModal";
import { Link } from "~/components/Link";
import { accountNavItems } from "~/config/navigation";
import { cn } from "~/utils/classnames";
import { truncate } from "~/utils/strings";

export const UserMenu = ({
  user,
  contact,
  className,
  ...props
}: { user: User | null; contact: Contact | null } & ComponentPropsWithoutRef<typeof Menu>) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isContactSelectionOpen, setIsContactSelectionOpen] = useState(false);

  if (!user) {
    return (
      <Button
        size="lg"
        variant="ghost"
        color="black"
        className={cn("shrink-0 max-lg:h-auto max-lg:px-2 max-lg:py-2", className)}
        onClick={() => navigate(`/connexion?redirect=${location.pathname}`)}
        data-testid="login-button"
      >
        <HiOutlineUser className="h-6 w-6 shrink-0 text-primary" />
        <span className="sr-only font-medium lg:not-sr-only">Se connecter</span>
      </Button>
    );
  }

  return (
    <>
      <Menu
        as="div"
        className={cn("inline-block shrink-0 text-left text-sm sm:relative", className)}
        {...props}
      >
        <Menu.Button as={Fragment}>
          <Button
            as="button"
            size="lg"
            variant="ghost"
            color="black"
            className="shrink-0 max-lg:h-auto max-lg:px-2 max-lg:py-2"
          >
            <HiOutlineUser className="h-6 w-6 shrink-0 text-primary" />
            <div className="sr-only flex shrink-0 flex-col text-left lg:not-sr-only">
              <span className="mb-1.5">Bonjour {contact?.firstName}</span>
              <span className="text-xs font-normal">
                {truncate(contact?.company.name || "", 20)}
              </span>
            </div>
          </Button>
        </Menu.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute left-1 right-1 z-40 mt-2 origin-top-right divide-y divide-grey-100 rounded-[8px] border border-grey-100 bg-white shadow-md sm:left-auto sm:right-0">
            <div className="whitespace-nowrap px-4 py-3">
              <div className="flex items-center justify-between gap-8">
                <div>
                  <p className="mb-2 text-sm font-semibold">
                    {contact?.firstName} {contact?.lastName}{" "}
                  </p>
                  <p className="text-xs text-grey-700">({contact?.company.name})</p>
                </div>

                <div className="flex grow-0 flex-col items-center justify-center gap-1 rounded-full bg-primary px-5 py-1 text-xs font-medium text-white">
                  <p>Code client</p>
                  <p>{contact?.code || ""}</p>
                </div>
              </div>
            </div>

            <Menu.Item>
              {({ close }) => (
                <button
                  className="block w-full px-4 py-2 text-start text-xs font-medium text-grey-900 hover:bg-grey-50"
                  onClick={() => {
                    setIsContactSelectionOpen(true);
                    close();
                  }}
                >
                  Changer d&apos;utilisateur
                </button>
              )}
            </Menu.Item>

            <div>
              {accountNavItems.map((item) => (
                <Fragment key={item.to}>
                  {item.children && item.children.length > 0 ? (
                    <Fragment key={item.to}>
                      <Menu.Item as={"div"} className="hidden md:block">
                        {({ active, close }) => (
                          <Link
                            to={item.to || "/"}
                            onClick={() => close()}
                            className={cn(
                              active ? "bg-grey-50 text-grey-900" : "text-grey-700",
                              "flex items-center gap-4 px-4 py-3 text-sm font-medium transition-colors"
                            )}
                          >
                            {item.icon ? <item.icon className="h-7 w-7 text-primary" /> : null}
                            {item.label}
                          </Link>
                        )}
                      </Menu.Item>
                      <Disclosure as="div" className="md:hidden">
                        <Disclosure.Button
                          className={cn(
                            "flex w-full items-center justify-start gap-4 px-4 py-3 text-sm font-medium text-grey-700 transition-colors hover:bg-grey-50 hover:text-grey-900"
                          )}
                        >
                          {item.icon ? <item.icon className="h-7 w-7 text-primary" /> : null}
                          {item.label}
                          <HiOutlineChevronDown className="ml-auto h-5 w-5 text-grey-400" />
                        </Disclosure.Button>
                        <Disclosure.Panel>
                          {item.children.map((subItem) => (
                            <Menu.Item key={subItem.label}>
                              {({ active }) => (
                                <Link
                                  to={subItem.to}
                                  className={cn(
                                    active ? "bg-grey-50 text-grey-800" : "text-grey-600",
                                    "flex items-center gap-4 py-3 pl-16 pr-4 text-sm font-medium transition-colors lg:hidden"
                                  )}
                                >
                                  {subItem.icon ? (
                                    <subItem.icon className="h-7 w-7 text-primary" />
                                  ) : null}
                                  {subItem.label}
                                </Link>
                              )}
                            </Menu.Item>
                          ))}
                        </Disclosure.Panel>
                      </Disclosure>
                    </Fragment>
                  ) : (
                    <Menu.Item key={item.to}>
                      {({ active }) => (
                        <Link
                          to={item.to || "/"}
                          className={cn(
                            active ? "bg-grey-50 text-grey-900" : "text-grey-700",
                            "flex items-center gap-4 px-4 py-3 text-sm font-medium transition-colors"
                          )}
                        >
                          {item.icon ? <item.icon className="h-7 w-7 text-primary" /> : null}
                          {item.label}
                        </Link>
                      )}
                    </Menu.Item>
                  )}
                </Fragment>
              ))}
            </div>

            <Form action={`/logout?redirect=${location.pathname}`} method="POST">
              <Menu.Item>
                {({ active }) => (
                  <button
                    type="submit"
                    className={cn(
                      active ? "bg-grey-50 text-danger-600" : "text-danger-600",
                      "flex w-full items-center gap-4 px-4 py-3 text-start text-sm font-medium transition-colors"
                    )}
                  >
                    <HiOutlineArrowRightOnRectangle
                      className={cn(
                        active ? "text-danger-600" : "text-danger-600",
                        "h-7 w-7 transition-colors"
                      )}
                    />
                    Déconnexion
                  </button>
                )}
              </Menu.Item>
            </Form>
          </Menu.Items>
        </Transition>
      </Menu>
      {user.contacts ? (
        <ChangeContactModal
          activeContact={contact}
          contacts={user.contacts}
          open={isContactSelectionOpen}
          onClose={() => setIsContactSelectionOpen(false)}
        />
      ) : null}
    </>
  );
};
