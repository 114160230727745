import { useFetcher, useNavigate } from "@remix-run/react";
import { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";

import { ButtonOld } from "~/components/ButtonOld";
import { InputSelect } from "~/components/InputSelect";
import { LoadingSpinner } from "~/components/LoadingSpinner";
import { Modal } from "~/components/Modal";
import { useManagedFetcher } from "~/hooks/use-managed-fetcher";

import type { ChangeContactModalProps } from "./ChangeContactModal.types";

export const ChangeContactModal = ({
  contacts,
  activeContact,
  open,
  onClose,
}: ChangeContactModalProps) => {
  const [wasSubmitted, setWasSubmitted] = useState(false);
  const navigate = useNavigate();

  const fetcher = useFetcher<any>();
  const [isFetcherLoading, isFetcherDone, isFetcherFailed] = useManagedFetcher({
    fetcher,
    toastSuccessMessage: "Compte client sélectionné avec succès.",
  });

  const closeFn = useCallback(
    (val: boolean) => {
      setWasSubmitted(false);
      onClose(val);
    },
    [onClose]
  );

  useEffect(() => {
    if (isFetcherDone && wasSubmitted) {
      closeFn(false);
    }
  }, [isFetcherDone, closeFn, wasSubmitted, navigate]);

  useEffect(() => {
    if (isFetcherLoading) {
      setWasSubmitted(true);
      toast.dismiss("contact-restriction-message");
    }
  }, [isFetcherLoading]);

  return (
    <Modal
      title="Veuillez selectionner un compte client"
      description={
        activeContact
          ? "Attention, si vous validez le changement d'utilisateur, vous risquez de perdre les produits ajoutés au comparateur."
          : null
      }
      isOpen={open}
      forceOpen={activeContact ? false : true}
      size="lg"
      onClose={() => onClose(false)}
    >
      <fetcher.Form method="post" action="/contact/select">
        <InputSelect
          className="mx-auto mt-8 max-w-xs"
          name="contact"
          id="contact"
          label="Compte client"
          placeholder="Sélectionnez un compte client"
          defaultValue={activeContact?.id || ""}
          options={contacts.map((contact) => ({
            id: contact.id,
            label: `${contact.code} - ${contact.company.name}`,
            value: contact.id.toString(),
          }))}
        />
        <div className="mt-8 flex flex-col justify-between gap-4 sm:flex-row">
          {!activeContact ? null : (
            <ButtonOld
              label="Annuler"
              variant="outline"
              color="black"
              onClick={() => closeFn(false)}
              type="button"
            />
          )}
          <ButtonOld
            label="Valider"
            variant="filled"
            color="black"
            type="submit"
            IconComponent={isFetcherLoading ? <LoadingSpinner /> : null}
            className={!activeContact ? "mx-auto" : ""}
          />
        </div>
      </fetcher.Form>
      {isFetcherFailed && fetcher.data?.error ? <div>{fetcher.data.error}</div> : null}
    </Modal>
  );
};
