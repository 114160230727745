import { useEffect, useState } from "react";
import { HiOutlineExclamationCircle, HiOutlineXMark } from "react-icons/hi2";
import { toast } from "react-toastify";
import type { Contact } from "~types/api/contact.types";

import { Button } from "~/components/Button";
import { getContactRestrictionMessage } from "~/services/contact-restrictions";

export function useContactRestrictionToast({ contact }: { contact: Contact | null }) {
  const [isRestrictionToastSeen, setIsRestrictionToastSeen] = useState(true);

  useEffect(() => {
    const contactId = contact?.id.toString();
    if (!contact || window.sessionStorage.getItem("contactRestrictionToastSeen") !== contactId) {
      window.sessionStorage.removeItem("contactRestrictionToastSeen");
    }
    setIsRestrictionToastSeen(
      window.sessionStorage.getItem("contactRestrictionToastSeen") === contactId
    );
  }, [contact]);

  const contactRestrictionMessage = getContactRestrictionMessage(contact || null);

  useEffect(() => {
    if (contact && contactRestrictionMessage && !isRestrictionToastSeen) {
      toast(
        <>
          <div className="flex items-center gap-3">
            <HiOutlineExclamationCircle className="h-6 w-6 shrink-0 text-warning-600" />
            <p className="text-lg text-grey-800">Information</p>
          </div>
          <p className="mt-4 leading-snug text-grey-800">{contactRestrictionMessage}</p>
          <p className="mt-2 leading-snug text-grey-800">
            Contactez-nous pour toute question à ce sujet.
          </p>
          <div className="mt-4 flex items-center justify-center gap-4">
            <Button
              size="sm"
              variant="ghost"
              as="link"
              to="/nous-contacter"
              className="!bg-transparent underline hover:text-primary-700"
              onClick={() => {
                setIsRestrictionToastSeen(true);
                contact?.id &&
                  window.sessionStorage.setItem(
                    "contactRestrictionToastSeen",
                    contact.id.toString()
                  );
                toast.dismiss("contact-restriction-message");
              }}
            >
              Nous contacter
            </Button>
          </div>
        </>,
        {
          autoClose: false,
          closeOnClick: false,
          hideProgressBar: true,
          type: "warning",
          position: "bottom-right",
          toastId: "contact-restriction-message",
          className: "border border-grey-100",
          icon: false,
          closeButton({ closeToast }) {
            return (
              <button
                onClick={(e) => closeToast(e)}
                className="absolute right-0 top-0 p-2 text-grey-800 hover:text-grey-900"
              >
                <HiOutlineXMark className="h-5 w-5 text-grey-600 hover:text-grey-800" />
              </button>
            );
          },
          onClose: () => {
            setIsRestrictionToastSeen(true);
            contact?.id &&
              window.sessionStorage.setItem("contactRestrictionToastSeen", contact.id.toString());
          },
        }
      );
    }
  }, [contact, contactRestrictionMessage, isRestrictionToastSeen]);
}
