import { useEffect } from "react";

export const Analytics = ({
  axeptioClientId,
  gaTrackingId,
  hotjarId,
  googleSiteVerification,
  nonce,
}: {
  axeptioClientId: string | null | undefined;
  gaTrackingId: string | null | undefined;
  hotjarId: string | null | undefined;
  googleSiteVerification: string | null | undefined;
  nonce: string;
}) => {
  useEffect(() => {
    if (typeof document !== "undefined" && window.axeptioInit) {
      window.axeptioInit();
    }
  }, []);

  return (
    <>
      {/* Google Site Verification */}
      {process.env.NODE_ENV === "production" && googleSiteVerification ? (
        <meta name="google-site-verification" content={googleSiteVerification} />
      ) : null}

      {/* Axeptio cookies managment */}
      {process.env.NODE_ENV === "production" && axeptioClientId ? (
        <>
          <script
            id="axeptio-init"
            nonce={nonce}
            dangerouslySetInnerHTML={{
              __html: `
                  function axeptioInit() {
                    window.axeptioSettings = {
                      clientId: "${axeptioClientId}",
                      cookiesVersion: "powr-connect-1.0.0",
                      googleConsentMode: {
                        default: {
                          analytics_storage: 'denied',
                          ad_storage: 'denied',
                          ad_user_data: 'denied',
                          ad_personalization: 'denied',
                          wait_for_update: 500
                        }
                      }
                    };
                    (function (d, s) {
                      var t = d.getElementsByTagName(s)[0], e = d.createElement(s);
                      e.async = true;
                      e.src = "//static.axept.io/sdk-slim.js";
                      t.parentNode.insertBefore(e, t);
                      console.info("Axeptio enabled.");
                    })(document, "script");
                  }
                  window.axeptioInit = axeptioInit;
                `,
            }}
          />
        </>
      ) : null}

      {/* Analytics */}
      {process.env.NODE_ENV === "production" && (gaTrackingId || hotjarId) ? (
        <>
          {/* Import Google Analytics External Script */}
          <script
            id="gtag-import"
            nonce={nonce}
            src={`https://www.googletagmanager.com/gtag/js?id=${gaTrackingId}`}
          />

          {/* Initialize analytics scripts based on Axeptio consent */}
          <script
            id="analytics-init"
            nonce={nonce}
            dangerouslySetInnerHTML={{
              __html: `
                  window._axcb = window._axcb || [];
                  window._axcb.push(function (axeptio) {
                    axeptio.on("cookies:complete", function (choices) {
                      ${
                        gaTrackingId
                          ? `if (choices.google_analytics) {
                              window.dataLayer = window.dataLayer || [];
                              function gtag() {
                                dataLayer.push(arguments);
                              }
                              gtag("js", new Date());
                              gtag("config", "${gaTrackingId}", {
                                page_path: window.location.pathname,
                              });
                              window.gtag = gtag;
                              window.gaEnabled = true;
                              console.info("Google Analytics enabled.");
                            }`
                          : ""
                      }
                      ${
                        hotjarId
                          ? `if (choices.hotjar) {
                              (function (h, o, t, j, a, r) {
                                h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                                h._hjSettings={hjid:${hotjarId},hjsv:6};
                                a=o.getElementsByTagName('head')[0];
                                r=o.createElement('script');r.async=1;
                                r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                                a.appendChild(r);
                              })(window, document, "https://static.hotjar.com/c/hotjar-", ".js?sv=");
                              console.info("Hotjar enabled.");
                            }`
                          : ""
                      }
                    });
                  });`,
            }}
          />
        </>
      ) : null}
    </>
  );
};
