import { TbArrowsDiff } from "react-icons/tb";

import { Link } from "~/components/Link";

export const ComparatorButton = ({ totalItems }: { totalItems: number | null }) => {
  return (
    <Link
      className="fixed right-0 top-64 z-30 hidden flex-col items-center justify-center gap-1 rounded-l-2xl bg-primary px-3.5 py-2.5 text-white shadow-sm transition-colors hover:bg-primary-600 active:bg-primary-700 sm:flex"
      to="/account/comparator"
      preventScrollReset={false}
    >
      {totalItems ? (
        <div className="absolute right-2 top-2 flex h-4 w-4 items-center justify-center rounded-full bg-white text-xs leading-none text-grey-950">
          <span>{totalItems}</span>
        </div>
      ) : null}
      <TbArrowsDiff className="h-6 w-6 md:h-8 md:w-8" />
      <p className="text-xs font-medium md:text-sm ">Comparer </p>
    </Link>
  );
};
